// @flow
import React from 'react';

import { Speedtest } from '../../types/Speedtest';
import styles from './Gauge.module.scss';

type Props = {
  speedtest: Speedtest,
};


// eslint-disable-next-line no-mixed-operators
const getMBps = (num) => `${Math.round(num / 1000)} Mbps`; // from Kbps to Mbps

const Gauge = ({ speedtest }: Props) => (
  <>
    <div className={styles.number}>
      <span className={styles.arrowVertical}>&darr;</span>
      {speedtest && getMBps(speedtest.download)}
    </div>
    <div className={styles.number}>
      <span className={styles.arrowVertical}>&uarr;</span>
      {speedtest && getMBps(speedtest.upload)}
    </div>
    <div className={styles.number}>
      <span className={styles.arrow}>&harr;</span>
      {speedtest && speedtest.ping}
      ms
    </div>
  </>
);

export default Gauge;
