// @flow
import React from 'react';

export const breakLines = (text: string) => {
  if (!text) {
    return null;
  }

  const lines = text.split('\n');
  return lines.map((line) => (
    <React.Fragment key={line}>
      {line}
      <br />
    </React.Fragment>
  ));
};
