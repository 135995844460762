// @flow
import React, {useEffect, useState} from 'react';

import styles from './TestsEndedModal.module.scss';
import EmptyModal from '../../../shared/components/EmptyModal';
import withPurgeLocalStorage from '../../../shared/components/withPurgeLocalStorage';
import { breakLines } from '../../../shared/utils/react';
import SVGIcon from '../../../shared/components/SVGIcon/SVGIcon';
import { useTranslate } from '../../../../lang/TranslationProvider';

type Props = {
  isOpen: false,
  purge: () => Promise<void>,
};

const TestsEndedModal = ({
  isOpen,
  purge,
}: Props) => {
  const { translate } = useTranslate();
  const [isSaved, setIsSaved] = useState(false);
  useEffect(() => {
    if (isOpen) {
      setTimeout(async () => {
        await purge();
        setIsSaved(true);
      }, 15000);
    }
  }, [isOpen, setIsSaved, purge]);

  return (
    <EmptyModal
      isOpen={isOpen}
      className={styles.testsendedmodal}
      modalClassName={styles.modal}
    >
      <div>
        <SVGIcon icon="CHECK_CIRCLE" />
      </div>
      <div className={styles.successContainer}>
        {
          !isSaved
          && (
          <>
            <h3>{translate('testsEndedModal.title')}</h3>
            <div className={styles.text}>{breakLines(translate('testsEndedModal.text'))}</div>
            <div className={styles.loaderContainer}>
              <div className={styles.loaderDot} />
              <div className={styles.loaderDot} />
              <div className={styles.loaderDot} />
            </div>
          </>
          )
        }
        {
          isSaved
          && (
          <>
            <h3>{translate('testsEndedModal.titleSaved')}</h3>
            <div className={styles.text}>{breakLines(translate('testsEndedModal.textSaved'))}</div>
          </>
          )
        }
      </div>
    </EmptyModal>
  );
}

export default withPurgeLocalStorage(TestsEndedModal);
