// @flow
import React, { useState, useRef, useEffect } from 'react';

import classnames from '../../utils/classnames';
import htmlId from '../../utils/htmlId';
import styles from './Input.module.scss';
import Info from '../Info';

type Props = {
  className?: string,
  value: string,
  title: string,
  onChange: (name: string, newValue: string, valid: boolean) => void,
  autoFocus: boolean,
  validate?: (value: string) => boolean,
  errorMessage?: string,
  onEnterPress: () => void,
  placeholder?: string,
  name: string,
  info?: string,
};

const Input = (props: Props) => {
  const [invalid, setInvalid] = useState(false);
  const id = useRef(htmlId());
  const input = useRef(null);

  const {
    autoFocus,
    name,
    onChange,
    onEnterPress,
    title,
    value,
    className,
    errorMessage,
    info,
    placeholder,
    validate,
  } = props;

  useEffect(() => {
    if (input.current && autoFocus) {
      input.current.focus();
    }
  }, [autoFocus]);

  const onChanged = (val: string) => {
    let valid = true;

    if (validate) {
      valid = validate(val);
    }

    setInvalid(!valid);

    if (onChange) {
      onChange(name, val, valid);
    }
  };

  const handleBlur = () => {
    onChanged(value);
  };

  const handleValueChange = (e: React.FormEvent<HTMLInputElement>) => {
    onChanged(e.currentTarget.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onEnterPress) {
      onEnterPress();
    }
  };

  const inputClasses = classnames(
    styles.input,
    !!title && styles.withTitle,
    invalid && styles.error,
  );

  return (
    <div
      className={classnames(styles.container, className)}
    >
      <label htmlFor={id.current}>
        <div className={styles.label}>{title}</div>
        {!!info && (<Info>{info}</Info>)}
        <input
          ref={input}
          id={id.current}
          value={value}
          onChange={handleValueChange}
          onBlur={handleBlur}
          className={inputClasses}
          onKeyPress={handleKeyPress}
          placeholder={placeholder}
        />
      </label>
      {invalid && (
        <div className={styles['error-message']}>
          {errorMessage}
        </div>
      )}
    </div>
  );
};

Input.defaultProps = {
  className: undefined,
  validate: undefined,
  errorMessage: '',
  placeholder: undefined,
  info: undefined,
};

export default Input;
