// @flow
import {
  INIT_AUTHORIZATION,
  INIT_AUTHORIZATION_ERROR,
  INIT_AUTHORIZATION_SUCCESS,
  INIT_TECHNICIAN,
} from './authorizationActionTypes';
import { Action } from '../shared/types/Action';

const initialState = {
  operation: null,
  bpid: null,
  isAuthorized: false,
  error: null,
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case INIT_AUTHORIZATION_SUCCESS:
      return {
        ...state,
        isAuthorized: true,
      };
    case INIT_AUTHORIZATION:
      return {
        ...state,
        isAuthorized: false,
        error: null,
      };
    case INIT_AUTHORIZATION_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };
    case INIT_TECHNICIAN: {
      const { operation, bpid } = action.payload;
      return {
        ...state,
        operation,
        bpid,
      };
    }
    default:
      return state;
  }
};
