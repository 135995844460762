// @flow
import React, {
  useState, useEffect, useCallback,
} from 'react';

import classnames from '../../../shared/utils/classnames';
import styles from './SpeedtestModal.module.scss';
import Modal from '../../../shared/components/Modal';
import Button from '../../../shared/components/Button';
import { Room } from '../../../shared/types/Room';
import { useTranslate } from '../../../../lang/TranslationProvider';

type Props = {
  className?: string,
  connectionType: String,
  isOpen: false,
  defaultRoom: Room,
  onEditClick: (room: Room) => void,
  onRequestClose: (room: Room) => void,
};

const SpeedtestModal = ({
  className, connectionType, onEditClick, onRequestClose, isOpen, defaultRoom,
}: Props) => {
  const { translate } = useTranslate();
  const speedTestOoklaUrl = 'https://vooinstall.speedtestcustom.com';
  const speedTestNperfJsAsyncUrl = 'https://ws.nperf.com/partner/js-async?l=b769db90-6fff-4e0d-a4f5-d40b235334af';
  const speedTestNperfProvider = true;
  const [isLoaderVisible, setIsLoaderVisible] = useState(true);
  const [room, setRoom] = useState(defaultRoom);
  const [isNew, setIsNew] = useState(false);
  const [title, setTitle] = useState('speedTestModal.title.testing');

  // const [scriptLoaded, setScriptLoaded] = useState(false); // Track script loaded status

  const handleEditClick = () => {
    onRequestClose(room);
    onEditClick(room);
  };

  const handleCloseAndSave = useCallback((testedRoom) => {
    onRequestClose(testedRoom);
  }, [onRequestClose]);

  const onTestFinished = useCallback((event) => {
    console.log("event", event);
    console.log("event.origin", event.origin);
    console.log("event.data", event.data);

    if (!speedTestNperfProvider && event.origin !== speedTestOoklaUrl) {
      return;
    } else if (speedTestNperfProvider && event.data.action !== 'nPerfGetLastResult') {
      return;
    }

    console.log("check");

    let testedRoom = {};

    if(!speedTestNperfProvider) {
      const {
        download,
        upload,
        latency,
        config,
      } = event.data;

      testedRoom = {
        ...room,
        connectionType,
        speedtest: {
          download,
          upload,
          ping: latency.minimum,
          jitter: Math.round(latency.jitter),
          speedtestId: config.testId,
          date: new Date().toISOString(),
        },
      };
    } else {
      console.log('lastResult', event.data.lastResult);
      const {lastResult} = event.data;
      testedRoom = {
        ...room,
        connectionType,
        speedtest: {
          download: Math.round(lastResult.speed.download.averageExcludingSlowStart/1000), // to force from bps to Kbps as Ookla
          upload: Math.round(lastResult.speed.upload.averageExcludingSlowStart/1000), // to force from bps to Kbps as Ookla
          ping: Math.round(lastResult.speed.latency.minimum),
          jitter: Math.round(lastResult.speed.latency.jitter),
          speedtestId: lastResult.resultId,
          date: new Date().toISOString(),
        },
      };
      console.log('testedRoom', testedRoom);
    }

    // setRoom(testedRoom);
    setTitle('speedTestModal.title.done');
    handleCloseAndSave(testedRoom);
  }, [connectionType, handleCloseAndSave, room, speedTestNperfProvider]);

  useEffect(() => {
    setRoom(defaultRoom);
    setIsNew(!defaultRoom || !defaultRoom.id);
    if (window.attachEvent) {
      window.attachEvent('onmessage', onTestFinished);
      return () => window.detachEvent('onmessage', onTestFinished);
    }
    window.addEventListener('message', onTestFinished);

    return () => window.removeEventListener('message', onTestFinished);
  }, [defaultRoom, onTestFinished]);


  useEffect(() => {
    if (isOpen && speedTestNperfProvider) {
      const script = document.createElement('script');
      script.src = speedTestNperfJsAsyncUrl;
      script.async = true;

      script.onload = () => {
        // Script has been loaded
        const iframeElement = document.getElementById('nPerfSpeedTest');
        const iframeContainer = document.getElementById('nPerfSpeedTestIframe');

        // Check if the element exists
        if (iframeElement && iframeContainer) {
          // Access and manipulate the iframe element here
          // For example, you can change its attributes or perform other actions.
          console.log('The iframe element with id "nPerfSpeedTest" was found.');
          iframeContainer.appendChild(iframeElement);
        } else {
          console.log('The iframe element with id "nPerfSpeedTest" was not found.');
        }
      };

      script.onerror = () => {
        // Handle script loading error if necessary
      };

      // Append the script to the document
      document.body.appendChild(script);

      return () => {
        // Cleanup function if the component unmounts
        document.body.removeChild(script);
      };
    }
  }, [speedTestNperfProvider, isOpen]);

  const hideLoader = () => {
    console.log('hideLoader');
    setIsLoaderVisible(false);
  };

  const renderLoader = () => {
    console.log('renderLoader');
    if (!isLoaderVisible) return null;
    return (
      <div className={styles.loaderContainer}>
        <div className={styles.loader} />
      </div>
    );
  };

  const renderChangeRoomBtn = () => {
    if (!isNew) return null;
    return (
      <Button
        secondary
        className={styles.bottomBtn}
        hidden
        onClick={handleEditClick}
      >
        {translate('speedTestModal.changeRoom')}
      </Button>
    );
  };

  const renderContent = () => (
    <>
      <h3>{room && room.name}</h3>
      {speedTestNperfProvider ? (
        <div id="nPerfSpeedTestIframe" />
      ) : (
        <>
          {renderLoader()}
          <iframe
            className={styles.iframe}
            frameBorder="0"
            src={speedTestOoklaUrl}
            title="Speedtest"
            onLoad={hideLoader}
          />
        </>
      )}
      {renderChangeRoomBtn()}
    </>
  );

  return (
    <div id="hello">
      <Modal
        isOpen={isOpen}
        title={translate(title)}
        canBeClosed
        onRequestClose={handleCloseAndSave}
      >
        <div
          className={classnames(styles.speedtestmodal, className)}
        >
          {renderContent()}
        </div>
      </Modal>
    </div>
  );
};

SpeedtestModal.defaultProps = {
  className: undefined,
};

export default SpeedtestModal;
