
// @flow
import React from 'react';
import EPaths from './EPath';

type Props = {
  icon: string,
};

const SVGIcon = ({ icon }: Props) => (
  <svg width="32" height="32" viewBox="0 0 32 32">
    <path d={EPaths[icon]} />
  </svg>
);

export default SVGIcon;
