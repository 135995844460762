// @flow
import React, { useState, useEffect } from 'react';

import classnames from '../../../shared/utils/classnames';
import { breakLines } from '../../../shared/utils/react';
import styles from './EndTestsModal.module.scss';
import Button from '../../../shared/components/Button';
import Modal from '../../../shared/components/Modal';
import { Room } from '../../../shared/types/Room';
import { useTranslate } from '../../../../lang/TranslationProvider';

type Props = {
  className?: string,
  isOpen: boolean,
  rooms: Room[],
  onRequestClose: () => void,
  onTestsSaved: () => void,
  saveTests: () => Promise<void>,
};


const EndTestsModal = (props: Props) => {
  const { translate } = useTranslate();
  const {
    isOpen,
    onRequestClose,
    onTestsSaved,
    rooms,
    saveTests,
    className,
  } = props;
  const [notOptimizedRooms, setNotOptimizedRooms] = useState([]);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const filterRooms = (allRooms: Room[] = []) =>
      allRooms.filter((r) => r.speedtest && r.speedtest.score < 3);
    setNotOptimizedRooms(filterRooms(rooms));
  }, [rooms, setNotOptimizedRooms]);

  const handleEndTestsClick = async () => {
    try {
      setSaving(true);
      setError(null);
      await saveTests();
      setSaving(false);
      onTestsSaved();
    } catch {
      setSaving(false);
      setError(translate('endTestsModal.errorMessage'));
    }
  };

  const renderRooms = () => (
    <div className={styles.roomsGroup}>
      <div className={styles.roomTitle}>
        {`${translate('endTestsModal.notOptimizedRooms')} :`}
      </div>
      <ul className={styles.rooms}>
        {notOptimizedRooms.map((r) => (
          <li key={r.id}>{r.name}</li>
        ))}
      </ul>
    </div>
  );

  const question = notOptimizedRooms.length ? 'notOptimized' : 'optimized';

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={!saving}
      title={translate('endTestsModal.title')}
      className={className}
    >
      <div className={styles.question}>
        {breakLines(translate(`endTestsModal.questions.${question}`))}
      </div>
      <div className={classnames(styles.roomsContainer)}>
        {!!notOptimizedRooms.length && renderRooms()}
      </div>
      <div className={styles.buttons}>
        <Button
          secondary
          onClick={onRequestClose}
          disabled={saving}
        >
          {translate('endTestsModal.cancel')}
        </Button>
        <Button
          className={styles.endTestsButton}
          green
          onClick={handleEndTestsClick}
          disabled={saving}
        >
          {translate('endTestsModal.endTests')}
        </Button>
      </div>
      {!!error && (<div className={styles.error}>{error}</div>)}
    </Modal>
  );
};

EndTestsModal.defaultProps = {
  className: undefined,
};

export default EndTestsModal;
