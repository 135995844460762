export default {
  app: {
    title: 'Speedtest',
    results: 'Resultaten van de test per kamer',
    endTests: 'Terminer',
    rooms: {
      livingRoom: 'Living room',
      kitchen: 'Kitchen',
      diningRoom: 'Dining room',
      bathroom: 'Bathroom',
      bedroom1: 'Room 1',
      bedroom2: 'Room 2',
      bedroom3: 'Room 3',
      office: 'Office',
      garage: 'Garage',
      patio: 'Patio',
      garden: 'Tuin',
      laundryRoom: 'Wasruimte',
      other1: 'Other 1',
      other2: 'Other 2',
      other3: 'Other 3',
    },
  },
  testRoomWidget: {
    room: {
      Cable: 'Câblé (Modem)',
      Wifi: 'WiFi (Modem)',
      CableCPL: 'Câblé (CPL)',
      WifiCPL: 'WiFi (CPL)',
    },
    testConnectionButton: {
      tested: 'Retester',
      edit: 'Modifier',
    },
  },
  roomModal: {
    roomName: 'Kies de kamer waar u de test wilt uitvoeren:',
    errorMessages: {
      emptyName: 'Veuillez entrer un nom',
      nameTaken: 'Ce nom est déjà pris',
    },
  },
  editTestModal: {
    cancelButtonText: 'Retour',
    validateButtonText: 'Enregistrer',
    title: 'Modifiez les informations',
    removeTest: 'Supprimer le test',
  },
  addTestModal: {
    info: 'Assurez-vous que vous êtes toujours bien connecté au réseau ci-dessous',
    title: 'Speedtest',
    cancelButtonText: 'Retour',
    validateButtonText: 'Verder',
  },
  addRoomWidget: {
    addRoom: 'Een andere kamer testen',
  },
  gauge: {
    notTested: 'Non testé',
  },
  endTestsModal: {
    title: 'Clôture des tests',
    questions: {
      optimized: 'Votre installation est optimisée.\nAvez-vous testé toutes les pièces habituelles ?',
      notOptimized: 'Certaines pièces ne sont pas optimisées. Parlez-en à votre technicien VOO pour qu\'il vous conseille.',
    },
    notOptimizedRooms: 'Pièces non optimisées',
    cancel: 'Continuer les tests',
    endTests: 'Terminer les tests',
    errorMessage: 'Une erreur s\'est produite.',
  },
  devices: {
    tablet: 'Tablette',
    smartphone: 'Smartphone',
    laptop: 'PC Portable',
  },
  configuration: {
    title: 'Speedtest',
    connectionQuestion: 'Test u uw internetverbinding via wifi of kabel?',
    Cable: 'MODEM Kabelverbinding',
    Wifi: 'Wifi MODEM-verbinding',
    CableCPL: 'CPL Kabelverbinding',
    WifiCPL: 'Wifi CPL-verbinding',
    cableInfo: 'Om deze verbindingstest uit te voeren, moet u controleren of uw 3G/4G uitgeschakeld is en of u verbonden bent met uw VOO netwerk.',
    startTestsButton: 'Verder',
    connectionErrorMessage: 'Veuillez entrer un type de connexion',
  },
  speedtest: {
    configuration: {
      connectionType: 'Test réalisé sur',
      button: 'Modifier les informations',
      Cable: 'Kabelverbinding',
      Wifi: 'Wifiverbinding',
    },
    testInfo: 'Opgelet, de gegevens van de test worden niet opgeslagen in myVOO. Sluit de test niet voordat u alle gewenste kamers hebt getest.',
  },
  testsEndedModal: {
    title: 'Sauvegarde en cours',
    text: 'Veuillez patienter s’il vous plait, la page se fermera automatiquement lorsque la sauvegarde sera terminée.',
  },
  speedTestModal: {
    title: {
      testing: 'Speedtest',
      done: 'Speedtest',
    },
    saveResults: 'De resultaten bekijken',
    changeRoom: 'Changer de pièce',
  },
};
