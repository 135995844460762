
// @flow
import React from 'react';
import styles from './TestInfos.module.scss';

const DataLevel = ({
  title1, title2, num,
}: Props) => (
  <div>
    <h3>
      {title1}
      <br />
      {title2}
    </h3>
    <span className={styles.number}>
      {num}
    </span>
    <strong>
      Mbps
    </strong>
  </div>
);

export default DataLevel;
