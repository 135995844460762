// @flow
import { Room } from '../types/Room';

const mapSpeed = (speed: number = 0) => Math.round(speed / 1000);

export const mapRoomToReport = (room: Room): any => ({
  name: room.name,
  connectionType: room.connectionType,
  date: room.speedtest.date,
  ping: room.speedtest.ping,
  jitter: room.speedtest.jitter,
  download: mapSpeed(room.speedtest.download),
  score: room.speedtest.score,
  testId: room.speedtest.speedtestId,
  upload: mapSpeed(room.speedtest.upload),
});
