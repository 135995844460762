// @flow
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { Action } from '../types/Action';

const createPersistConfig = (key, blacklist) => ({ key, storage, blacklist });

export default (key: string, reducer: (state: any, action: Action) => any, blacklist?: string[]) =>
  persistReducer(createPersistConfig(key, blacklist), reducer);
