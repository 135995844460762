// @flow
import React, { useRef, useEffect } from 'react';

import classnames from '../../utils/classnames';
import styles from './Dropdown.module.scss';
import htmlId from '../../utils/htmlId';
import { useTranslate } from '../../../../lang/TranslationProvider';

type Props = {
  className?: string,
  items: any[] | null,
  defaultValue?: string,
  onChange: (name: string, value: string) => void,
  name: string,
  title?: string,
};

const Dropdown = ({
  className, items, defaultValue, onChange, name, title,
}: Props) => {
  const { translate } = useTranslate();
  const id = useRef(htmlId());
  const defaultSelected = defaultValue || items[0];
  const stateItems = (items || []).map((value) => ({ value, selected: value === defaultValue }));
  const hasTitle = !!title;
  const selectClasses = classnames(
    styles.content,
    hasTitle && styles.withTitle,
  );
  useEffect(() => {
    onChange(name, defaultSelected);
  }, [name, defaultSelected, onChange]);

  const handleValueChange = (e: React.SyntheticEvent<HTMLSelectElement>) => {
    onChange(name, e.currentTarget.value);
  };

  return (
    <div className={classnames(styles.dropdown, className)}>
      <label htmlFor={id.current}>{title}</label>
      <div className={selectClasses}>
        <select
          defaultValue={defaultSelected}
          id={id.current}
          onChange={handleValueChange}
        >
          {stateItems.map((i) => (
            <option
              key={i.value}
              value={i.value}
            >
              {translate(`app.rooms.${i.value}`)}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

Dropdown.defaultProps = {
  defaultValue: undefined,
  title: undefined,
  className: undefined,
};

export default Dropdown;
