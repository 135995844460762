// @flow
/* eslint react/no-multi-comp: 0 */
import React from 'react';

import PurgeLocalStorageContext from '../contexts/PurgeLocalStorageContext';
import addContext from '../utils/addContext';

const withPurgeLocalStorage = (component: React.Component<P>) => addContext(component, PurgeLocalStorageContext, 'purge');

export default withPurgeLocalStorage;
