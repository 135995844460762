// @flow
import React from 'react';
import { Redirect } from 'react-router-dom';

import { CONFIGURATION } from '../../../constants/routes';
import PrivateRoute from './PrivateRoute';

type Props = {
  component: React.Component,
  path: string,
  hasConfiguration: boolean,
};

const AppRoute = ({ hasConfiguration, ...rest }: Props) => (
  <PrivateRoute
    canAccessRoute={hasConfiguration}
    fallbackComponent={({ from }) => (
      <Redirect to={{ pathname: CONFIGURATION, state: { from } }} />
    )}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  />
);

export default AppRoute;
