// @flow
import { post, get } from './httpService';
import { mapRoomToReport } from '../mappers/room';
import { Room } from '../types/Room';

const route = 'customer/net-reports';

export const getSpeedtests = (headers?: any) => get(route, headers);
export const saveSpeedtests = (
  rooms: Room[],
) => {
  const data = {
    reports: rooms.map(mapRoomToReport),
  };

  console.log(saveSpeedtests, route, data);
  
  return post(route, data);
};
