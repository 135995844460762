// @flow
import React, { useRef } from 'react';

import htmlId from '../../utils/htmlId';
import styles from './Radio.module.scss';

type Props = {
  value: string;
  title: string;
  name: string;
  selectedValue: string;
  onChange: (value: string) => void;
};

const Radio = (props: Props) => {
  const {
    title,
    value,
    name,
    onChange,
    selectedValue,
  } = props;
  const id = useRef(htmlId());

  return (
    <>
      <input
        type="radio"
        id={id.current}
        name={name}
        value={value}
        className={styles.vooRadio}
        checked={selectedValue === value}
        onChange={() => onChange(name, value)}
      />
      <label htmlFor={id.current} className={styles.label}>
        {title}
      </label>
    </>
  );
};

export default Radio;
