// @flow
import axios from 'axios';
import Cookies from 'universal-cookie';

import store from '../../../store';
import { API_URL } from '../config';

type Technician = {
  operation: string,
  bpid: string,
};


const cookies = new Cookies();

export const buildHeaders = (technician?: Technician) => {
  const headers = {
    'Content-Type': 'application/json',
    'X-XSRF-TOKEN': cookies.get('XSRF-TOKEN') || '',
  };

  if (technician) {
    const { operation, bpid } = technician;
    const key = `${bpid}:${operation}`;

    return {
      ...headers,
      technician: btoa(key),
    };
  }

  return headers;
};

const buildHeadersFromState = () => {
  const { authorization } = store.getState();
  let technicianConfiguration = null;

  if (authorization.operation && authorization.bpid) {
    const { operation, bpid } = authorization;
    technicianConfiguration = { operation, bpid };
  }

  return buildHeaders(technicianConfiguration);
};

const request = (method: string, url: string, data?: any, customHeaders) => {
  const headers = customHeaders || buildHeadersFromState();
  const apiUrl = `${API_URL}/${url}`;
  return axios({
    method,
    url: apiUrl,
    headers,
    data,
    withCredentials: !headers.Authorization,
  })
    .catch((reason) => {
      const { response } = reason;
      if (response.status === 401) {
        const { location } = response.headers;

        if (location) {
          window.location.replace(location);
        }
      }
      throw reason;
    });
};

export const get = (url: string, headers?: any) => request('get', url, null, headers);
export const post = (url: string, data: any, headers?: any) => request('post', url, data, headers);
