// @flow
import { connect } from 'react-redux';

import Configuration from './components/Configuration';
import * as configurationActions from './configurationActions';

const mapStateToProps = ({ configuration }) => {
  const { connectionType, hasConfiguration } = configuration;
  return {
    connectionType,
    hasConfiguration,
  };
};

export default connect(mapStateToProps, {
  saveConfiguration: configurationActions.saveConfiguration,
})(Configuration);
