// @flow
import React from 'react';

import classnames from '../../utils/classnames';
import styles from './Info.module.scss';
import { breakLines } from '../../utils/react';

type Props = {
  children: string,
  isBlue?: boolean,
};

const Info = ({ children, isBlue }: Props) => (
  <div
    className={classnames(styles.info, isBlue && styles.infoBlue)}
  >
    <div className={styles.container}>
      <div className={styles.icon} />
      <div className={styles.content}>
        {breakLines(children)}
      </div>
    </div>
  </div>
);

Info.defaultProps = {
  isBlue: false,
};

export default Info;
