import { connect } from 'react-redux';

import Speedtest from './components/Speedtest';
import { addRoom, removeRoom, updateRoom } from './SpeedTestActions';
import {
  getNextId,
  getSpeedtests,
  getCanSave,
} from './speedtestSelectors';
import { saveSpeedtests } from '../shared/services/reports';

const mapStateToProps = (state) => {
  const { configuration } = state;
  const { connectionType } = configuration;
  const rooms = getSpeedtests(state);
  return {
    connectionType,
    rooms,
    nextId: getNextId(state),
    canSave: getCanSave(state),
    saveTests: () => saveSpeedtests(
      rooms,
    ),
  };
};

const mapDispatchToProps = {
  addRoom,
  removeRoom,
  updateRoom,
};

export default connect(mapStateToProps, mapDispatchToProps)(Speedtest);
