// @flow
import React from 'react';

import styles from './Modal.module.scss';
import EmptyModal from '../EmptyModal';
import SVGIcon from '../SVGIcon/SVGIcon';

type Props = {
  children: React.ReactNode,
  className?: string,
  isOpen: boolean,
  canBeClosed: boolean,
  onRequestClose: () => void,
  title: string,
  shouldCloseOnOverlayClick?: boolean,
};

const Modal = ({
  children,
  className,
  isOpen,
  canBeClosed,
  onRequestClose,
  title,
  shouldCloseOnOverlayClick,
}: Props) => {
  const renderCloseButton = () => (
    <button type="button" className={styles.close} onClick={onRequestClose}>
      <SVGIcon icon="CLOSE" />
    </button>
  );

  return (
    <EmptyModal
      isOpen={isOpen}
      className={className}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <div className={styles.title}>
        <h2>{title.toUpperCase()}</h2>
        {!!canBeClosed && renderCloseButton()}
      </div>
      <div className={styles.content}>
        {children}
      </div>
    </EmptyModal>
  );
};

Modal.defaultProps = {
  className: undefined,
  shouldCloseOnOverlayClick: false,
};

export default Modal;
