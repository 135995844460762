// @flow
import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import styles from './RoomModal.module.scss';
import Modal from '../../../shared/components/Modal';
import Input from '../../../shared/components/Input';
import Button from '../../../shared/components/Button';
import Dropdown from '../../../shared/components/Dropdown';
import { Room } from '../../../shared/types/Room';
import RadioGroup from '../../../shared/components/RadioGroup';
import Radio from '../../../shared/components/Radio';
import * as configurationActions from '../../../configuration/configurationActions';
import { useTranslate } from '../../../../lang/TranslationProvider';

export interface RoomModalProps {
  onSaveClick: (room: Room) => void;
  saveConfiguration: (configuration: any) => void,
  roomNameValidation: (roomName: string) => boolean;
  isOpen: boolean;
  showConnectionField?: boolean;
  onRequestClose: () => void;
  rooms: { name: string, value: string }[];
  connectionType: String;
  cancelButtonText: string;
  validateButtonText: string;
  title: string;
  room?: Room;
  renderFooter?: () => React.ReactNode;
}

const RoomModal = (props: RoomModalProps) => {
  const { translate } = useTranslate();
  const {
    cancelButtonText,
    isOpen,
    showConnectionField,
    saveConfiguration,
    onRequestClose,
    onSaveClick,
    roomNameValidation,
    connectionType,
    rooms,
    title,
    validateButtonText,
    renderFooter,
    room,
  } = props;
  const [roomValid, setRoomValid] = useState(false);
  const [connectionValid, setConnectionValid] = useState(false);
  const [roomName, setRoomName] = useState('');
  const [emptyRoomNameError, setEmptyRoomNameError] = useState('');
  const [roomValue, setRoomValue] = useState(rooms[0]);
  const [roomIsCustom, setRoomIsCustom] = useState(false);
  const [roomConnectionType, setroomConnectionType] = useState('');

  useEffect(() => {
    setRoomValid(!!rooms.length && !rooms[0].isCustom);
    const defaultRoom = room || rooms[0];
    if (defaultRoom) {
      setRoomValid(true);
      setRoomName(defaultRoom.name);
      setRoomValue(defaultRoom.keyName);
      setRoomIsCustom(defaultRoom.isCustom);
    }
    setroomConnectionType(connectionType);
    setConnectionValid(true);
  }, [room, rooms, connectionType]);
  const handleRoomChange = (name: string, value: string) => {
    setRoomIsCustom(value.startsWith('other'));
    setRoomValue(value);
  };
  const handleNameChange = (name: string, value: string) => {
    setRoomName(value);
  };

  const handleSaveEdit = () => {
    if (!roomValid || (showConnectionField && !connectionValid)) { return; }
    const roomToSave = {
      ...room,
      name: !roomIsCustom ? translate(`app.rooms.${roomValue}`) : roomName,
      keyName: roomValue,
      roomConnectionType,
    };
    onSaveClick(roomToSave);
    onRequestClose();
  };

  const validateRoomName = (value: string) => {
    const isValid = roomNameValidation(value);
    if (!value) {
      setRoomValid(false);
      setEmptyRoomNameError(translate('roomModal.errorMessages.emptyName'));
      return false;
    }

    setRoomValid(isValid);
    setEmptyRoomNameError(translate('roomModal.errorMessages.nameTaken'));
    return isValid;
  };

  const validateConnection = (value: string) => {
    const isValid = !!value;
    setConnectionValid(isValid);
    return isValid;
  };

  const handleConnectionChange = (key: string, val: string) => {
    setroomConnectionType(val);
    saveConfiguration({ connectionType: val });
  };

  const renderConnectionField = () => (
    <div className={styles.fields}>
      <legend>{translate('configuration.connectionQuestion')}</legend>
      <RadioGroup
        selectedValue={roomConnectionType}
        validate={validateConnection}
        name="connectionType"
        errorMessage={translate('configuration.connectionErrorMessage')}
        onChange={handleConnectionChange}
      >
        <Radio
          title={translate('configuration.Cable')}
          value="Cable"
        />
        <Radio
          title={translate('configuration.Wifi')}
          value="Wifi"
        />
        <Radio
          title={translate('configuration.CableCPL')}
          value="CableCPL"
        />
        <Radio
          title={translate('configuration.WifiCPL')}
          value="WifiCPL"
        />
      </RadioGroup>
    </div>
  );

  return (
    <Modal
      isOpen={isOpen}
      title={title}
      onRequestClose={onRequestClose}
      className={styles.modal}
    >
      <div className={styles.content}>
        <Dropdown
          items={rooms}
          name="roomKey"
          title={translate('roomModal.roomName')}
          onChange={handleRoomChange}
          defaultValue={roomValue}
        />
        {!!roomIsCustom && (
          <Input
            value={roomName}
            onChange={handleNameChange}
            validate={validateRoomName}
            errorMessage={emptyRoomNameError}
            name="roomName"
            placeholder={translate('roomModal.roomName')}
          />
        )}
        {!!showConnectionField && renderConnectionField()}
        <div className={styles.buttonsContainer}>
          <Button secondary onClick={onRequestClose}>
            {cancelButtonText}
          </Button>
          <Button
            disabled={!roomValid || (showConnectionField && !connectionValid)}
            onClick={handleSaveEdit}
            green
          >
            {validateButtonText}
          </Button>
        </div>
        {renderFooter && renderFooter()}
      </div>
    </Modal>
  );
};

RoomModal.defaultProps = {
  room: undefined,
  renderFooter: null,
  showConnectionField: false,
};
const mapStateToProps = ({ configuration }) => {
  const { roomConnectionType } = configuration;
  return {
    roomConnectionType,
  };
};
export default connect(mapStateToProps, {
  saveConfiguration: configurationActions.saveConfiguration,
})(RoomModal);
