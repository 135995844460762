import { combineReducers } from 'redux';

import persistReducer from './modules/shared/utils/persistReducer';
import configurationReducer from './modules/configuration/configurationReducer';
import speedTestReducer from './modules/speedtest/speedTestReducer';
import authorizationReducer from './modules/authorization/authorizationReducer';

export default combineReducers({
  configuration: persistReducer('configuration', configurationReducer),
  speedTest: persistReducer('speedtest', speedTestReducer),
  authorization: persistReducer('authorization', authorizationReducer, ['isAuthorized']),
});
