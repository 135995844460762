// @flow
import React, { PropsWithChildren, useReducer, useContext } from 'react';
// import ReactMarkdown from 'react-markdown';

import fr from './fr';
import en from './en';
import nl from './nl';
import de from './de';

export interface StringProps {
  [prop: string]: string
}

export type LG = 'en' | 'fr' | 'nl' | 'de';
export const languageOptions: LG[] = ['fr', 'en', 'nl', 'de'];
export const regEx = new RegExp('^/(fr|en|nl|de)/');
const translations: any = {
  fr, en, nl, de,
};

const setDefaultLanguage = (): LG => {
  // first we check if there is a language in url path
  // then we check the browser language
  // then, if we don't manage the browser language, we give a default language
  const pathLg = languageOptions.find((x) => x === window.location.pathname.slice(1, 3));
  const navLg = languageOptions.find((x) => x === navigator.language.split('-')[0]);
  if (pathLg) return pathLg;
  if (navLg) return navLg;
  return languageOptions[0];
};

const findTranslation = (keys: string[], trs: any): string | null => {
  const key = keys[0];
  const found = trs[key];

  if (!found) {
    return null;
  }

  if (keys.length === 1) {
    return found;
  }

  return findTranslation(keys.slice(1), found);
};

const getTranslate = (language: LG) => (key: string, params: StringProps = {}): string => {
  if (!key) {
    throw new Error('You must provide a key');
  }
  const keys = key.split('.');
  let translation = findTranslation(keys, translations[language]);

  if (translation === null) {
    return `MISSING TRANSLATION: '${key}'`;
  }

  translation = Object.keys(params).reduce((str, key) => str.replace(`{${key}}`, params[key]), translation);
  return translation;
};

// const getMarkdown = (language: LG) => (key: string, params: StringProps = {}): any => {
//   const translation = getTranslate(language)(key, params)
//   return <ReactMarkdown source={translation} />;
// }

const getDictionary = (key: LG): any => {
  if (!key) {
    throw new Error('You must provide a key');
  }
  const translation = translations[key];
  if (translation === null) {
    return `MISSING TRANSLATION: '${key}'`;
  }
  return translation;
};

const initialState = {
  langCode: setDefaultLanguage(),
  translate: getTranslate(setDefaultLanguage()),
  // markdown: getMarkdown(setDefaultLanguage()),
  dictionary: getDictionary(setDefaultLanguage()),
};

export const TranslationContext = React.createContext(initialState);

export const TranslationProvider = ({ children }: PropsWithChildren<{}>) => {
  const reducer = (state = initialState, action: any) => {
    switch (action.type) {
      case 'setLanguage':
        return {
          langCode: action.payload,
          translate: getTranslate(action.payload),
          // markdown: getMarkdown(action.payload),
          dictionary: getDictionary(action.payload),
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const setLanguage = (language: LG) => dispatch({ type: 'setLanguage', payload: language });

  return (
    // @ts-ignore
    <TranslationContext.Provider value={{ ...state, setLanguage }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslate = () => useContext(TranslationContext);
