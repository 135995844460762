// @flow
/* eslint react/no-multi-comp: 0 */
import React from 'react';

function addContext<P>(
  Component: React.Component<P>,
  context: React.Context,
  propName: string,
): React.Component<P> {
  class WithContext extends React.Component<P> {
    // eslint-disable-next-line react/static-property-placement
    static contextType = context;

    render() {
      const { forwardedRef, ...rest } = this.props;
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Component ref={forwardedRef} {...{ ...rest, [propName]: this.context }} />
      );
    }
  }

  return React.forwardRef((props, ref) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <WithContext {...props} forwardedRef={ref} />
  ));
}

export default addContext;
