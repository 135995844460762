export default {
  app: {
    title: 'Main rooms test',
    results: 'Test results per room',
    endTests: 'Finish',
    rooms: {
      livingRoom: 'Living room',
      kitchen: 'Kitchen',
      diningRoom: 'Dining room',
      bathroom: 'Bathroom',
      bedroom1: 'Room 1',
      bedroom2: 'Room 2',
      bedroom3: 'Room 3',
      office: 'Office',
      garage: 'Garage',
      patio: 'Patio',
      garden: 'Garden',
      laundryRoom: 'Laundry room',
      other1: 'Other 1',
      other2: 'Other 2',
      other3: 'Other 3',
    },
  },
  testRoomWidget: {
    room: {
      Cable: 'Cable (Modem)',
      Wifi: 'WiFi (Modem)',
      CableCPL: 'Cable (CPL)',
      WifiCPL: 'WiFi (CPL)',
    },
    testConnectionButton: {
      tested: 'Retest',
      edit: 'Edit',
    },
  },
  roomModal: {
    roomName: 'Select the room in which you wish to perform the test :',
    errorMessages: {
      emptyName: 'Please enter a name',
      nameTaken: 'This name is already taken',
    },
  },
  editTestModal: {
    cancelButtonText: 'Back',
    validateButtonText: 'Save',
    title: 'Edit informations',
    removeTest: 'Delete test',
  },
  addTestModal: {
    info: 'Make sure you are still connected to the network below',
    title: 'Speedtest',
    cancelButtonText: 'Back',
    validateButtonText: 'Next',
  },
  addRoomWidget: {
    addRoom: 'Test a new room',
  },
  gauge: {
    notTested: 'Not tested',
  },
  endTestsModal: {
    title: 'End of tests',
    questions: {
      optimized: 'Your installation is optimized.\nHave you tested all the usual rooms?',
      notOptimized: 'Some rooms are not optimized. Talk to your VOO technician for advice.',
    },
    notOptimizedRooms: 'Rooms not optimized',
    cancel: 'Continue testing',
    endTests: 'Finish testing',
    errorMessage: 'An error occured.',
  },
  configuration: {
    title: 'Speedtest',
    connectionQuestion: 'Are you performing the test with a Wifi connection or a cable connection?',
    Cable: 'MODEM cable connection',
    Wifi: 'MODEM wifi connection',
    CableCPL: 'CPL cable connection',
    WifiCPL: 'CPL wifi connection',
    cableInfo: 'To perform this connection test, make sure that your 3G/4G is properly deactivated and that you are connected to your VOO network.',
    startTestsButton: 'Next',
    connectionErrorMessage: 'Please enter a connection type',
  },
  speedtest: {
    configuration: {
      connectionType: 'Test performed on',
      button: 'Edit informations',
      Wifi: 'Wifi connection',
      Cable: 'Cable connection',
    },
    testInfo: 'Please note, the test data are not saved in myVOO. Don’t leave the test before having tested all the rooms you want to test.',
  },
  testsEndedModal: {
    title: 'Sauvegarde en cours',
    text: 'Veuillez patienter s’il vous plait, la page se fermera automatiquement lorsque la sauvegarde sera terminée.',
  },
  speedTestModal: {
    title: {
      testing: 'Speedtest',
      done: 'Speedtest',
    },
    saveResults: 'View results',
    changeRoom: 'Change room',
  },
};
