// @flow
/* eslint jsx-a11y/click-events-have-key-events: 0 */
/* eslint jsx-a11y/no-static-element-interactions: 0 */
import React from 'react';

import classnames from '../../utils/classnames';
import styles from './Widget.module.scss';

type Props = {
  children: React.ReactNode,
  className?: string,
  onClick?: (e: React.SyntheticEvent<HTMLDivElement>) => void,
};

const Widget = ({ children, className, onClick }: Props) => (
  <div
    className={classnames(styles.widget, className)}
    onClick={onClick}
  >
    {children}
  </div>
);

Widget.defaultProps = {
  className: undefined,
  onClick: undefined,
};

export default Widget;
