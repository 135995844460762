// @flow
import React from 'react';

import classnames from '../../utils/classnames';
import styles from './Button.module.scss';

type Props = {
  children: React.ReactNode,
  onClick: (e: React.FormEvent<HTMLButtonElement>) => void,
  className?: string,
  secondary: boolean,
  green: boolean,
  disabled: boolean,
};

const Button = ({
  onClick,
  children,
  className,
  secondary,
  green,
  disabled,
}: Props) => {
  const classes = classnames(
    styles.button,
    green && !disabled && styles.green,
    secondary && !disabled && styles.secondary,
    className,
  );
  return (
    <button
      type="button"
      className={classes}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  className: undefined,
};

export default Button;
