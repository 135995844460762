// @flow
/* eslint-env browser */
import React from 'react';
import { useSelector } from 'react-redux';

import styles from './Speedtest.module.scss';
import TestsContainer from '../TestsContainer';
import { useTranslate } from '../../../../lang/TranslationProvider';
import { getSpeedtests } from '../../speedtestSelectors';

type Props = {
  connectionType: string,
  rooms: Room[],
  addRoom: (id: number) => void,
  removeRoom: (roomId: number) => void,
  updateRoom: (room: Room) => void,
  nextId: number,
  canSave: boolean,
  calculateSpeedScore: (speed: number) => number,
  saveTests: () => Promise<void>,
};

const Speedtest = ({
  connectionType,
  removeRoom,
  rooms,
  addRoom,
  updateRoom,
  nextId,
  canSave,
  calculateSpeedScore,
  saveTests,
}: Props) => {
  const speedtests = useSelector(getSpeedtests);
  const { translate } = useTranslate();
  const predefinedRooms = translate('app.rooms');
  const canAddTest = Object.keys(predefinedRooms)
    .some(
      (pr) => speedtests.every((s) => pr !== s.keyName),
    );
  const getTitle = () => (rooms.length > 0 ? 'app.results' : 'app.title');

  return (
    <div className={styles.container}>
      <h1>{translate(getTitle())}</h1>
      <TestsContainer
        connectionType={connectionType}
        removeRoom={removeRoom}
        rooms={rooms}
        addRoom={addRoom}
        updateRoom={updateRoom}
        nextId={nextId}
        canAddTest={canAddTest}
        canSave={canSave}
        calculateSpeedScore={calculateSpeedScore}
        saveTests={saveTests}
      />
    </div>
  );
};

export default Speedtest;
