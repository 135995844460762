// @flow
import React, { useState } from 'react';

import styles from './RadioGroup.module.scss';
import Info from '../Info';

type Props = {
  children: any,
  name: string,
  selectedValue: string,
  onChange: (name: string, newValue: string, valid: boolean) => void,
  validate?: (value: string) => boolean,
  errorMessage?: string,
  info?: string,
};

const RadioGroup = (props: Props) => {
  const {
    children,
    name,
    selectedValue,
    onChange,
    validate,
    errorMessage,
    info,
  } = props;
  const [isValid, setIsValid] = useState(true);

  const handleValueChange = (fieldName, value) => {
    const valid = validate(value);
    setIsValid(valid);
    if (onChange) {
      onChange(fieldName, value, valid);
    }
  };

  const renderErrorMessage = () => {
    if (isValid) {
      return null;
    }

    return (
      <div className={styles['error-message']}>
        {errorMessage}
      </div>
    );
  };

  return (
    <>
      {React.Children.map(children, (child) => React.cloneElement(
        child,
        {
          name,
          onChange: handleValueChange,
          selectedValue,
        },
      ))}
      {!isValid && renderErrorMessage()}
      {!!info && <Info isBlue>{info}</Info>}
    </>
  );
};

RadioGroup.defaultProps = {
  validate: undefined,
  errorMessage: '',
  info: undefined,
};

export default RadioGroup;
