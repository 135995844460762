// @flow
import React from 'react';
import { Route } from 'react-router-dom';

type Props = {
  component: React.Component,
  fallbackComponent: React.Component,
  path: string,
  canAccessRoute: boolean,
};

const PrivateRoute = ({
  component: Component,
  fallbackComponent: FallbackComponent,
  canAccessRoute,
  ...rest
}: Props) =>
  (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={({ location, ...props }) => {
        if (canAccessRoute) {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <Component {...props} />;
        }
        return (
          <FallbackComponent from={location} />
        );
      }}
    />
  );

export default PrivateRoute;
