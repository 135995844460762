// @flow
import React, { useState } from 'react';
import { withRouter } from 'react-router';

import { SPEEDTEST } from '../../../../constants/routes';
import classnames from '../../../shared/utils/classnames';
import styles from './Configuration.module.scss';
import Button from '../../../shared/components/Button';
import Radio from '../../../shared/components/Radio';
import RadioGroup from '../../../shared/components/RadioGroup';
import { useTranslate } from '../../../../lang/TranslationProvider';

type Props = {
  className?: string,
  history: {
    push: (location: any) => void,
  },
  saveConfiguration: (configuration: any) => void,
  connectionType: string,
};
const Configuration = (props: Props) => {
  const {
    className, history, saveConfiguration, connectionType,
  } = props;

  const { translate } = useTranslate();
  const [cType, setCType] = useState(connectionType);
  const [validation, setValidation] = useState({
    connectionType: !!connectionType,
  });

  const handleBeginTestsClick = () => {
    const keys = Object.keys(validation);
    const isValid = keys.every((key) => validation[key]);

    if (!isValid) return;
    saveConfiguration({ connectionType: cType });
    history.push({ pathname: SPEEDTEST });
  };

  const handleValueChange = (name, value, isValid) => {
    const hasKey = Object.prototype.hasOwnProperty.call(validation, name);

    if (hasKey) {
      validation[name] = isValid;
    }
    setCType(value);
    setValidation(validation);
  };

  const isFormValid = () => {
    const keys = Object.keys(validation);
    return keys.every((key) => validation[key]);
  };

  const renderContent = () => (
    <div className={styles.content}>
      <h1>{translate('configuration.title')}</h1>
      <div className={styles.fields}>
        <legend>{translate('configuration.connectionQuestion')}</legend>
        <RadioGroup
          selectedValue={cType}
          validate={(value) => !!value}
          name="connectionType"
          errorMessage={translate('configuration.connectionErrorMessage')}
          info={translate('configuration.cableInfo')}
          onChange={handleValueChange}
        >
          <Radio
            title={translate('configuration.Cable')}
            value="Cable"
          />
          <Radio
            title={translate('configuration.Wifi')}
            value="Wifi"
          />
          <Radio
            title={translate('configuration.CableCPL')}
            value="CableCPL"
          />
          <Radio
            title={translate('configuration.WifiCPL')}
            value="WifiCPL"
          />
        </RadioGroup>
        <div className={styles.buttonContainer}>
          <Button
            className={styles.beginTestsButton}
            onClick={handleBeginTestsClick}
            disabled={!isFormValid()}
            green
          >
            {translate('configuration.startTestsButton')}
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <div className={classnames(styles.configuration, className)}>
      {renderContent()}
    </div>
  );
};

Configuration.defaultProps = {
  className: undefined,
};

export default withRouter(Configuration);
