import { createSelector } from 'reselect';

const getSpeedtestsData = ({ speedTest }) => speedTest.data;
const getSpeedtestsIds = ({ speedTest }) => speedTest.ids;

export const getSpeedtests = createSelector(
  [getSpeedtestsData, getSpeedtestsIds],
  (data, ids) => ids.map((id) => ({
    ...data[id],
    isCustom: data[id].keyName.startsWith('other'),
  })),
);

export const getNextId = createSelector(
  [getSpeedtestsIds],
  (ids) => {
    if (!ids.length) {
      return 1;
    }
    return Math.max(...ids) + 1;
  },
);

export const getCanSave = createSelector(
  [getSpeedtests],
  (speedtests) => !!speedtests.length,
);
