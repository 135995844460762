// @flow
/* eslint-env browser */
import React, { useEffect } from 'react';
import queryString from 'query-string';
import {
  Switch,
  Route,
  Redirect,
  BrowserRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';

import './styles/fonts.scss';
import './styles.scss';
import styles from './App.module.scss';
import PurgeLocalStorageContext from './modules/shared/contexts/PurgeLocalStorageContext';
import { CONFIGURATION, SPEEDTEST } from './constants/routes';
import AppRoute from './modules/shared/components/AppRoute';
import * as authorizationActions from './modules/authorization/authorizationActions';
import Configuration from './modules/configuration/ConfigurationContainer';
import Speedtest from './modules/speedtest/SpeedTestContainer';
import { persistor } from './store';
import { TranslationProvider } from './lang/TranslationProvider';

type Props = {
  hasConfiguration: boolean,
  isAuthorized: boolean,
  authorize: (technicianKey: string, bpid: string, operation: string) => void,
};

const App: React.FC = ({
  hasConfiguration,
  isAuthorized,
  authorize,
}: Props) => {
  const params = queryString.parse(window.location.search);
  const { bpid, operation } = params;

  useEffect(() => {
    authorize(bpid, operation);
  }, [authorize, bpid, operation]);

  if (!isAuthorized) {
    return <div className={styles.loading}>Chargement</div>;
  }

  return (
    <BrowserRouter>
      <PurgeLocalStorageContext.Provider value={persistor.purge}>
        <TranslationProvider>
          <Switch>
            <Route path={CONFIGURATION} component={Configuration} />
            <AppRoute
              path={SPEEDTEST}
              component={Speedtest}
              hasConfiguration={hasConfiguration}
            />
            <Redirect from="/" to={hasConfiguration ? SPEEDTEST : CONFIGURATION} />
          </Switch>
        </TranslationProvider>
      </PurgeLocalStorageContext.Provider>
    </BrowserRouter>
  );
};

const mapStateToProps = ({ configuration, authorization }) => ({
  hasConfiguration: configuration.hasConfiguration,
  isAuthorized: authorization.isAuthorized,
});

const mapDispatchToProps = {
  authorize: authorizationActions.authorize,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
