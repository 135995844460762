// @flow
import React, {useState} from 'react';

import styles from './TestsContainer.module.scss';
import TestRoomWidget from '../TestRoomWidget';
import AddRoomWidget from '../AddRoomWidget';
import EndTestsModal from '../EndTestsModal';
import AddTestModal from '../AddTestModal';
import SpeedtestModal from '../SpeedtestModal';
import TestsEndedModal from '../TestsEndedModal';
import TestInfos from '../TestInfos';
import Button from '../../../shared/components/Button';
import { Room } from '../../../shared/types/Room';
import { useTranslate } from '../../../../lang/TranslationProvider';

type Props = {
  rooms: Room[],
  nextId: number,
  connectionType: string,
  canAddTest: boolean,
  canSave: boolean,
  addRoom: (id: number, room: Room) => void,
  updateRoom: (room: Room) => void,
  saveTests: () => Promise<void>,
};

const TestsContainer = ({
  rooms,
  nextId,
  connectionType,
  canAddTest,
  canSave,
  addRoom,
  updateRoom,
  saveTests,
}: Props) => {
  const { translate } = useTranslate();
  const [isEndTestsModalOpen, setIsEndTestsModalOpen] = useState(false);
  const [isTestsEndedModalOpen, setIsTestsEndedModalOpen] = useState(false);
  const [isAddTestModalOpen, setIsAddTestModalOpen] = useState(false);
  const [showConnectionField, setShowConnectionField] = useState(false);
  const [isSpeedTestModalOpen, setIsSpeedTestModalOpen] = useState(false);
  const [speedTestRoom, setSpeedTestRoom] = useState(undefined);

  const toggleEndTestsModalVisible = () => {
    setIsEndTestsModalOpen(!isEndTestsModalOpen);
  };

  const toggleAddTestsModalVisible = (cField: boolean) => {
    setIsAddTestModalOpen(!isAddTestModalOpen);
    setShowConnectionField(cField);
  };

  const handleEndTestsClick = () => {
    setIsTestsEndedModalOpen(true);
    setIsEndTestsModalOpen(!isEndTestsModalOpen);
  };

  const handleSaveRoomClick = (room: Room) => {
    setIsSpeedTestModalOpen(false);
    if (room && room.speedtest) {
      if (!room.id) {
        addRoom(nextId, room);
        return;
      }
      updateRoom(room);
    }
  };

  const openSpeedtest = (room: Room) => {
    setSpeedTestRoom(room);
    setIsSpeedTestModalOpen(!isSpeedTestModalOpen);
  };

  const renderAddRoomWidget = () => {
    if (!canAddTest || rooms.length > 0) return null;

    return (
      <AddRoomWidget onClick={() => toggleAddTestsModalVisible(false)} />
    );
  };


  const renderAddRoom = () => {
    if (!canAddTest || !rooms || rooms.length === 0) return null;

    return (
      <Button secondary onClick={() => toggleAddTestsModalVisible(true)}>{translate('addRoomWidget.addRoom')}</Button>
    );
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.colMd6}>
          {renderAddRoomWidget()}
          <div className={styles.content}>
            {rooms.map((room) => (
              <TestRoomWidget
                key={room.id}
                room={room}
                onTestAgainClick={openSpeedtest}
              />
            ))}
          </div>
        </div>
        <div className={styles.colMd6}>
          <TestInfos />
        </div>
      </div>
      <div className={styles.endTestsContainer}>
        {renderAddRoom()}
        <Button
          disabled={!canSave}
          className={styles.mlAuto}
          green
          onClick={toggleEndTestsModalVisible}
        >
          {translate('app.endTests')}
        </Button>
      </div>
      <AddTestModal
        isOpen={isAddTestModalOpen}
        showConnectionField={showConnectionField}
        onRequestClose={() => toggleAddTestsModalVisible(false)}
        onSaveClick={openSpeedtest}
        connectionType={connectionType}
        rooms={rooms}
      />
      {!!speedTestRoom && (
        <SpeedtestModal
          defaultRoom={speedTestRoom}
          isOpen={isSpeedTestModalOpen}
          onEditClick={() => toggleAddTestsModalVisible(true)}
          onRequestClose={handleSaveRoomClick}
          connectionType={connectionType}
        />
      )}
      <EndTestsModal
        isOpen={isEndTestsModalOpen}
        rooms={rooms}
        onRequestClose={toggleEndTestsModalVisible}
        onTestsSaved={handleEndTestsClick}
        saveTests={saveTests}
      />
      <TestsEndedModal
        isOpen={isTestsEndedModalOpen}
      />
    </>
  );
}

export default TestsContainer;
