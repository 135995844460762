// @flow
import { SAVE_CONFIGURATION } from './configurationActionTypes';
import { Action } from '../shared/types/Action';

const initialState = {
  connectionType: null,
  hasConfiguration: false,
};

export default (state = initialState, action: Action) => {
  switch (action.type) {
    case SAVE_CONFIGURATION: {
      const { payload } = action;
      return {
        connectionType: payload.connectionType,
        hasConfiguration: true,
      };
    }
    default:
      return state;
  }
};
