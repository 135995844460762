// @flow
import { combineReducers } from 'redux';

import { Action } from '../shared/types/Action';
import {
  PATCH_TEST,
  ADD_TEST,
  REMOVE_TEST,
} from './SpeedTestActionTypes';
import { Room } from '../shared/types/Room';

const setTest = (state, payload) => {
  const { room }: { room: Room } = payload;
  const { id } = room;
  return {
    ...state,
    [id]: {
      id,
      value: room.value,
      name: room.name,
      connectionType: room.connectionType,
      keyName: room.keyName,
      speedtest: room.speedtest,
    },
  };
};

const removeTest = (state, payload) => {
  const { id } = payload;
  const { [id]: testToRemove, ...tests } = state;
  return tests;
};

const speedtestsReducer = (state = {}, action: Action) => {
  switch (action.type) {
    case PATCH_TEST:
    case ADD_TEST:
      return setTest(state, action.payload);
    case REMOVE_TEST:
      return removeTest(state, action.payload);
    default:
      return state;
  }
};

const speedTestsIdsReducer = (state = [], action: Action) => {
  switch (action.type) {
    case ADD_TEST:
      return [...state, action.payload.room.id];
    case REMOVE_TEST:
      return state.filter((id) => id !== action.payload.id);
    default:
      return state;
  }
};

export default combineReducers({
  ids: speedTestsIdsReducer,
  data: speedtestsReducer,
});
