// @flow
import React, { useState, useEffect } from 'react';

import type { RoomModalProps } from './RoomModal';
import RoomModal from './RoomModal';
import { Room } from '../../shared/types/Room';
import { useTranslate } from '../../../lang/TranslationProvider';

interface Props extends RoomModalProps {
  rooms: Room[],
}

const AddTestModal = (props: Props) => {
  const { translate } = useTranslate();
  const {
    rooms: propRooms,
    showConnectionField,
  } = props;
  const [rooms, setRooms] = useState([]);
  const predefinedRooms = translate('app.rooms');
  useEffect(() => {
    setRooms(Object.keys(predefinedRooms).filter((pr) => propRooms.every((r) => r.keyName !== pr)));
  }, [propRooms, predefinedRooms]);

  const handleRoomNameValidation = (title: string) => Object.values(predefinedRooms)
    .every((r) => r.toLowerCase() !== title.toLowerCase());

  return (
    <RoomModal
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      info={translate('addTestModal.info')}
      rooms={rooms}
      showConnectionField={showConnectionField}
      cancelButtonText={translate('addTestModal.cancelButtonText')}
      validateButtonText={translate('addTestModal.validateButtonText')}
      title={translate('addTestModal.title')}
      roomNameValidation={handleRoomNameValidation}
    />
  );
};

export default AddTestModal;
