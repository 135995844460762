// @flow
import createAction from '../shared/utils/createAction';
import {
  INIT_AUTHORIZATION,
  INIT_AUTHORIZATION_ERROR,
  INIT_AUTHORIZATION_SUCCESS,
  INIT_TECHNICIAN,
} from './authorizationActionTypes';
import { getSpeedtests } from '../shared/services/reports';
import { buildHeaders } from '../shared/services/httpService';

const tryAuthorization = (operation: string, bpid: string) => (dispatch) => {
  let parameters = null;
  if (operation && bpid) {
    parameters = { bpid, operation };
    dispatch(createAction(INIT_TECHNICIAN, parameters));
  }
  const headers = buildHeaders(parameters);
  return getSpeedtests(headers);
};

export const authorize = (bpid: string, operation: string) =>
  async (dispatch) => {
    dispatch(createAction(INIT_AUTHORIZATION));
    try {
      await dispatch(tryAuthorization(operation, bpid));
      dispatch(createAction(INIT_AUTHORIZATION_SUCCESS));
    } catch (error) {
      dispatch(createAction(INIT_AUTHORIZATION_ERROR, error));
    }
  };
