// @flow
import React from 'react';
import ReactModal from 'react-modal';

import styles from './EmptyModal.module.scss';
import classnames from '../../utils/classnames';

ReactModal.setAppElement('#root');

type Props = {
  children: React.ReactNode,
  isOpen: boolean,
  onRequestClose: () => void,
  shouldCloseOnOverlayClick?: boolean,
  className?: string,
  modalClassName?: string,
};

const EmptyModal = ({
  children,
  isOpen,
  onRequestClose,
  shouldCloseOnOverlayClick,
  className,
  modalClassName,
}: Props) =>
  (
    <ReactModal
      isOpen={isOpen}
      className={classnames(styles.container, modalClassName)}
      overlayClassName={styles.overlay}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      <div
        className={classnames(styles.modal, className)}
      >
        {children}
      </div>
    </ReactModal>
  );

EmptyModal.defaultProps = {
  shouldCloseOnOverlayClick: false,
  className: '',
  modalClassName: '',
};

export default EmptyModal;
