export default {
  app: {
    title: 'Speedtest',
    results: 'Testergebnisse nach Räumen',
    endTests: 'Terminer',
    rooms: {
      livingRoom: 'Wohnzimmer',
      kitchen: 'Küche',
      diningRoom: 'Esszimmer',
      bathroom: 'Badezimmer',
      bedroom1: 'Schlafzimmer',
      bedroom2: 'Schlafzimmer 2',
      bedroom3: 'Schlafzimmer 3',
      office: 'Büro',
      garage: 'Garage',
      patio: 'Terrasse',
      garden: 'Garten',
      laundryRoom: 'Wäschekammer',
      other1: 'Andere 1',
      other2: 'Andere 2',
      other3: 'Andere 3',
    },
  },
  testRoomWidget: {
    room: {
      Cable: 'Câblé (Modem)',
      Wifi: 'WiFi (Modem)',
      CableCPL: 'Câblé (CPL)',
      WifiCPL: 'WiFi (CPL)',
    },
    testConnectionButton: {
      tested: 'Retester',
      edit: 'Modifier',
    },
  },
  roomModal: {
    roomName: 'Wählen Sie den Raum aus, in dem Sie den Test durchführen möchten:',
    errorMessages: {
      emptyName: 'Veuillez entrer un nom',
      nameTaken: 'Ce nom est déjà pris',
    },
  },
  editTestModal: {
    cancelButtonText: 'Retour',
    validateButtonText: 'Enregistrer',
    title: 'Modifiez les informations',
    removeTest: 'Supprimer le test',
  },
  addTestModal: {
    info: 'Assurez-vous que vous êtes toujours bien connecté au réseau ci-dessous',
    title: 'Speedtest',
    cancelButtonText: 'Retour',
    validateButtonText: 'Étape suivante',
  },
  addRoomWidget: {
    addRoom: 'Einen neuen Raum testen',
  },
  gauge: {
    notTested: 'Non testé',
  },
  endTestsModal: {
    title: 'Clôture des tests',
    questions: {
      optimized: 'Votre installation est optimisée.\nAvez-vous testé toutes les pièces habituelles ?',
      notOptimized: 'Certaines pièces ne sont pas optimisées. Parlez-en à votre technicien VOO pour qu\'il vous conseille.',
    },
    notOptimizedRooms: 'Pièces non optimisées',
    cancel: 'Continuer les tests',
    endTests: 'Terminer les tests',
    errorMessage: 'Une erreur s\'est produite.',
  },
  devices: {
    tablet: 'Tablette',
    smartphone: 'Smartphone',
    laptop: 'PC Portable',
  },
  configuration: {
    title: 'Speedtest',
    connectionQuestion: 'Testen Sie Ihre Wifi- oder Kabelverbindung?',
    Cable: 'MODEM-Kabelverbindung',
    Wifi: 'Wifi-MODEM-Verbindung',
    CableCPL: 'CPL-Kabelverbindung',
    WifiCPL: 'Wifi-CPL-Verbindung',
    cableInfo: 'Um diesen Test Ihrer Verbindung durchzuführen, stellen Sie bitte sicher, dass 3G/4G deaktiviert wurde und Sie mit Ihrem VOO-Netz verbunden sind.',
    startTestsButton: 'Weiter',
    connectionErrorMessage: 'Veuillez entrer un type de connexion',
  },
  speedtest: {
    configuration: {
      connectionType: 'Test réalisé sur',
      button: 'Modifier les informations',
      Cable: 'Kabelverbindung',
      Wifi: 'Wifi-Verbindung ',
    },
    testInfo: 'Achtung: Die Testdaten werden nicht in myVOO gespeichert. Verlassen Sie den Test nicht, bevor Sie nicht alle gewünschten Räume getestet haben.',
  },
  testsEndedModal: {
    title: 'Sauvegarde en cours',
    text: 'Veuillez patienter s’il vous plait, la page se fermera automatiquement lorsque la sauvegarde sera terminée.',
  },
  speedTestModal: {
    title: {
      testing: 'Speedtest',
      done: 'Speedtest',
    },
    saveResults: 'Ergebnisse anzeigen',
    changeRoom: 'Changer de pièce',
  },
};
