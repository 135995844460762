// @flow
import React from 'react';
import styles from './TestInfos.module.scss';
// Commented code might be needed in case of wifi@install opening to public
// import Info from '../../../shared/components/Info';
import DataLevel from './DataLevel';

// type Props = {
//   translate: (key: string) => string,
// };

const TestInfos = (
  // { translate }: Props
) => (
  <>
    <div className={styles.dataLevels}>
      <DataLevel title1="Emails" title2="IoT" num="1" />
      <DataLevel title1="Video" title2="Chat" num="4" />
      <DataLevel title1="Stream" title2="HD" num="5" />
      <DataLevel title1="Surf" title2="Web" num="20" />
      <DataLevel title1="Gaming" title2="Stream 4K" num="+25" />
    </div>
    {/* <Info isBlue>{translate('speedtest.testInfo')}</Info> */}
  </>
);

export default TestInfos;
