// @flow
import React from 'react';

import classnames from '../../../shared/utils/classnames';
import styles from './TestRoomWidget.module.scss';
import { Room } from '../../../shared/types/Room';
import Widget from '../../../shared/components/Widget';
import Button from '../../../shared/components/Button';
import Gauge from '../../../shared/components/Gauge';
import { useTranslate } from '../../../../lang/TranslationProvider';

type Props = {
  className?: string,
  onTestAgainClick: (room: Room) => void,
  room: Room,
};

const TestRoomWidget = ({
  className,
  onTestAgainClick,
  room,
}: Props) => {
  const { translate } = useTranslate();
  const styleName = classnames(styles.testroomwidget, className);

  return (
    <Widget className={classnames(styles.widget, styleName)}>
      <div className={styles.container}>
        <div>
          <h2 className={styles.title}>{room.name}</h2>
          <span className={styles.badge}>
            {translate(`testRoomWidget.room.${room.connectionType}`)}
          </span>
        </div>

        <Button secondary onClick={() => { onTestAgainClick(room); }}>
          {translate('testRoomWidget.testConnectionButton.tested')}
        </Button>
        <div className={styles.gaugeData}>
          <Gauge speedtest={room.speedtest} />
        </div>
      </div>
    </Widget>
  );
};

TestRoomWidget.defaultProps = {
  className: undefined,
};

export default TestRoomWidget;
