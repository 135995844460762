// @flow
import createAction from '../shared/utils/createAction';
import {
  PATCH_TEST,
  ADD_TEST,
  REMOVE_TEST,
} from './SpeedTestActionTypes';
import { Room } from '../shared/types/Room';

export const updateRoom = (room: Room) => createAction(PATCH_TEST, { room });
export const addRoom = (id: number, room: Room) =>
  createAction(ADD_TEST, { room: { ...room, id } });
export const removeRoom = (roomId: number) => createAction(REMOVE_TEST, { id: roomId });
