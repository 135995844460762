// @flow
import React from 'react';

import classnames from '../../../shared/utils/classnames';
import styles from './AddRoomWidget.module.scss';
import Widget from '../../../shared/components/Widget';
import { useTranslate } from '../../../../lang/TranslationProvider';

type Props = {
  className?: string,
  onClick: (e: React.FormEvent<HTMLButtonElement>) => void,
};

const AddRoomWidget = ({ className, onClick }: Props) => {
  const { translate } = useTranslate();
  return (
    <Widget className={classnames(styles.addroomwidget, className)} onClick={onClick}>
      <div className={styles.container}>
        <div className={styles.icon} />
        <div className={styles.content}>
          {translate('addRoomWidget.addRoom')}
        </div>
      </div>
    </Widget>
  );
};

AddRoomWidget.defaultProps = {
  className: undefined,
};

export default AddRoomWidget;
